import React, { useState, useMemo, useRef, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Index, connectStateResults, connectAutoComplete } from "react-instantsearch-dom"
import SearchBox from '~components/SearchBox'
import SearchResults from '~components/SearchResults'
import Section from '~components/Section'
// import { RefinementList } from 'react-instantsearch-dom';
import RefinementList from '~components/RefinementList'
// import Autocomplete, { getAlgoliaResults } from '~components/Autocomplete'
import CurrentRefinements from '~components/CurrentRefinements'
import Button from '~components/Button'
import qs from 'qs'
import { useLocation } from '@reach/router'
import { useQueryParam, DelimitedArrayParam, StringParam, withDefault } from "use-query-params"
import { Filter } from '~components/Svg'
import Seo from '~components/Seo'
import { mobile, tablet } from '~styles/global'
import NoResultsContent from '~components/NoResultsContent'

const HasHits = connectStateResults(({ searchResults, children = null, alternate = null }) => {
  const hitCount = searchResults && searchResults.nbHits
	const [renderReady, setRenderReady] = useState(false)
	useEffect(() => {
		setRenderReady(false)
		window.setTimeout(() => setRenderReady(true), 400)
	}, [hitCount])
  return hitCount > 0 ? (
		children
  ) : renderReady ? alternate : null
})

const Autocomplete = ({ hits, currentRefinement, refine, className, visible = null }) => visible && (
  <div className={className}>
    {hits[0]?.hits?.map(hit => (
				// currentRefinement !== hit.query &&
				<>
					<button onClick={() => refine(hit.query)} key={hit.objectID}>{hit.query}</button><br/>
				</>
    ))}
  </div>
)

const CustomAutocomplete = connectAutoComplete(Autocomplete)


const Search = () => {

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

	const [searchState, setSearchState] = useState({})

	const [query, setQuery] = useQueryParam('q', StringParam)
	const [entry, setEntry] = useQueryParam('e', withDefault(StringParam))

	const location = useLocation()

	const createURL = state => `?${qs.stringify(state)}`

	const createSearchPayload = (q, t, prev) => ({
		query: q || '',
		page: prev?.page ?? 1,
		indices: {
			Brands: {
					page: prev?.indices?.Brands?.page ?? 1
			}
		}
	})

	function onSearchStateChange(updatedSearchState) {
		setQuery(updatedSearchState.query)
		setSearchState(createSearchPayload(query, [], updatedSearchState))
  }

	const initialLoad = useRef(true)

  useEffect(() => {
		if(location.pathname.includes('search')){
			setSearchState(prevSearchState => createSearchPayload(query, [], prevSearchState))
		}
		if(initialLoad){
			initialLoad.current = false
		}
  }, [query])

  return(
    <>
			<Seo 
        title='Search'
      />
      <div>
        <InstantSearch
          searchClient={searchClient}
					// routing={true}
          indexName="Suggestions"
					searchState={searchState}
					onSearchStateChange={onSearchStateChange}
					createURL={createURL}
        >
					<SearchHeader>
						 <StyledSearchBox />
						 <StyledAutocomplete
						 	visible={query}
							openOnFocus={true}
						/>
					</SearchHeader>
					{entry !== 'brands' &&
							<Index indexName='Articles'>
								<HasHits>
									{/* NOTE: This conditional HasHits component causes slow response from typing on develop. It seems to work ok on prod, but if there are errors this is the first thing to remove when testing. */}
								{/* <RefinementList attribute="tags"/> */}
								<ResultGrid>
									<SearchResults heading='Journal' />
								</ResultGrid>
								</HasHits>
							</Index>
						
					}
					<Index indexName='Brands'>
						{/* <RefinementList 
								open={true}
								close={() => null}
								attribute="tags"
								facetOrdering={false}
								operator="and"
								limit={999999}
								entry={entry}
								setEntry={setEntry}
							/> */}
						{entry !== 'articles' &&
							<HasHits>
								{/* NOTE: This conditional HasHits component causes slow response from typing on develop. It seems to work ok on prod, but if there are errors this is the first thing to remove when testing. */}
									<ResultGrid>
									<SearchResults heading='Directory' query={query || ''} />
								</ResultGrid>
							</HasHits>
						}
					</Index>
					{/* Temporarily wrapping this component in the brands index so it doesn't appear when there are no suggestion results */}
					<Index indexName='Brands'>
						<HasHits
							alternate={
								<>
								<NoResults>
									<NoResultsText>
										<h6>Sorry, we're having trouble finding results for “{query}”</h6>
										<p className="highlight-s">Check the spelling or try a more general term. Remember, our Directory is home to hundreds of Australian fashion labels. You can browse it <Link to='/directory'>here</Link> and refine with our handy filter function, or visit our Journal to see more specific style and product-based edits. Still can’t find what you’re looking for? Email us at <a href='mailto:hello@homeroom.com.au'>hello@homeroom.com.au</a> and we’ll get our best people on it.</p>
									</NoResultsText>
								</NoResults>
								<NoResultsContent />
								</>
								
							}
						></HasHits>
					</Index>
        </InstantSearch>
      </div>
    </>
  )
}

const ResultGrid = styled(Section)`
	margin-bottom: 50px;
	> div { 
		row-gap: 45px;
	}
`

const StyledSearchBox = styled(SearchBox)`
	grid-column: span 9;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 1rem;
	}
`

const SearchHeader = styled(Section)`
	padding-top: 52px;
`

const StyledAutocomplete = styled(CustomAutocomplete)`
	margin-top: 30px;
	grid-column: 1 / 8;
`

const RefineButtonWrap = styled.div`
	grid-column: span 3;
	display: flex;
	align-items: flex-end;
	margin-bottom: 23px;
	${mobile}{
		display: none;
	}
`

const RefineButtonWrapMobile = styled(RefineButtonWrap)`
	display: none;
	flex-grow: 0;
	margin: 0 15px 0 15px;
	${mobile}{
		display: block;
	}
`

const RefineButton = styled(Button)`
	display: flex;
	align-items: center;
	margin-left: auto;
	svg {
		width: 30px;
		margin-right: 15px;
	}
`

const RefineButtonText = styled.div`
	background: var(--yellow);
	padding: 5px 12px 7px;
	font-weight: 700;
`

const StyledCurrentRefinements = styled(CurrentRefinements)`
	${mobile}{
		flex-grow: 1;
	}
`

const Controls = styled.div`
	display: flex;
	margin-top: 20px;
	${tablet}{
		margin-top: 10px;
	}
`

const NoResults = styled(Section)`
	/* min-height: 60vh; */
`

const NoResultsText = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
		margin-top: 2em;
	}
	h6 {
		margin-bottom: 0.5em;
	}
	a {
		/* text-decoration: underline; */
	}
`

export default Search